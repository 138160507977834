import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';
import { SessionStorage } from '@/enums/storage.enum';
import { authenticatedRoute } from './router/authenticated';
import { notFoundRoute } from './router/pageNotFound.route';
import { unauthenticatedRoute } from './router/unauthenticated';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [authenticatedRoute, unauthenticatedRoute, notFoundRoute],
  scrollBehavior: (_to, _from, savedPosition) => savedPosition || { x: 0, y: 0 }
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record: any) => record.meta.authorized) && localStorage.getItem(SessionStorage.TOKEN) === null) {
    store.commit('auth/unauthorize');
    return next({ name: 'login' });
  }
  next();
});

export default router;
