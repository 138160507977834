import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.module';
import navigation from './modules/navigation.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, navigation }
});
