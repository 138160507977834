/* eslint-disable no-unused-vars */
export enum Role {
  Admin = 'Admin',
  User = 'User'
}

export enum RoleDisplay {
  Admin = 'Administrator',
  User = 'Koordynator'
}

export enum RoleDisplayPlural {
  Admin = 'Administratorzy',
  User = 'Koordynatorzy'
}
