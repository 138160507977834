import { ObjectList } from '../interfaces/objectList';
import { Patient } from '../interfaces/patient';
import { BaseService } from './base.service';
import Client from './client';

export class PatientService extends BaseService<Patient> {
  protected resource: string;

  public history(patientId: number): Promise<ObjectList<History>> {
    return Client.get(`/${this.resource}/${patientId}/history`).then(response => response.data);
  }

  constructor() {
    super();
    this.resource = 'patient';
  }
}

export default new PatientService();
