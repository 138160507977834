import { Account } from '../interfaces/account';
import { Profile } from '../interfaces/profile';
import { BaseService } from './base.service';
import Client from './client';

/**
 * Serwis obługki konta użytkownika
 *
 * @export
 * @class AccountService
 * @extends {BaseService<Account>}
 */
export class AccountService extends BaseService<Account> {
  protected resource: string;

  /**
   * Zaloguj
   *
   * @param {string} userName
   * @param {string} password
   * @returns {Promise<{ profile: Profile; token: string; refreshToken: string }>}
   * @memberof AccountService
   */
  public login(userName: string, password: string): Promise<{ profile: Profile; token: string; refreshToken: string }> {
    return Client.post(`/${this.resource}/authenticate`, { userName, password }).then(res => {
      const profile: Profile = {
        id: res.data.id,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        role: res.data.role
      };
      return { profile, token: res.data.jwtToken, refreshToken: res.data.refreshToken };
    });
  }

  /**
   * Odśwież token
   *
   * @param {string} token
   * @returns {Promise<{ profile: Profile; token: string; refreshToken: string }>}
   * @memberof AccountService
   */
  public refreshToken(token: string): Promise<{ profile: Profile; token: string; refreshToken: string }> {
    return Client.post(`/${this.resource}/refresh-token`, { token }).then(res => {
      const profile: Profile = {
        id: res.data.id,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        role: res.data.role
      };
      return { profile, token: res.data.jwtToken, refreshToken: res.data.refreshToken };
    });
  }

  /**
   * Anuluj token
   *
   * @param {string} token
   * @returns {Promise<void>}
   * @memberof AccountService
   */
  public revokeToken(token: string): Promise<void> {
    return Client.post(`/${this.resource}/revoke-token`, { token });
  }

  constructor() {
    super();
    this.resource = 'accounts';
  }
}

export default new AccountService();
