import { AbstractModule } from './abstract.module';

class NavigationModule extends AbstractModule {
  public state: any = { drawer: undefined };

  public mutations = {
    setDrawer(localState: any, drawer: any): void {
      localState.drawer = drawer;
    }
  };
  public actions = {
    toggleDrawer(context: any): void {
      context.commit('setDrawer', !context.state.drawer);
    }
  };
}

export default new NavigationModule();
