<template>
  <v-app id="user_app">
    <top-bar app />

    <v-main>
      <div class="page-content">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from './TopBar';

export default {
  components: { TopBar }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
#user_app {
  background-color: $color-background;
}
</style>
