import { RouteConfig } from 'vue-router';

import RouterView from '@/components/RouterView.vue';
import Users from '@/views/authenticated/user/Users.vue';

export const usersRoute: RouteConfig = {
  path: 'users',
  meta: { title: 'Użytkownicy' },
  component: RouterView,
  children: [{ name: 'users', path: '', component: Users }]
};
