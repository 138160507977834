<template>
  <div>
    <v-tabs @change="onChange">
      <div class="tab-selector">
        <v-tab v-for="(tab, index) in tabs" :key="index" class="tab-item">
          <v-icon class="mr-3">{{ tab.icon }}</v-icon>
          {{ tab.title }}
        </v-tab>
      </div>
    </v-tabs>

    <div class="tab-header">
      <v-row>
        <v-col cols="12" md="6" class="py-0 justify-center">
          <v-text-field
            rounded
            filled
            prepend-inner-icon="mdi-account-search"
            label="Szukaj"
            class="tab__search-input"
            v-debounce="onSearch"
          />
        </v-col>

        <v-col cols="12" md="6" class="py-0 d-flex justify-end">
          <!-- Akcje -->
          <slot name="actions" />
        </v-col>
      </v-row>
    </div>
    <!-- Zawartość -->
    <slot />
  </div>
</template>

<script>
/** @gvuese
 * Służy do obudowania tabelki z wynikami
 * @group core
 */
export default {
  props: {
    // Lista zakładek
    tabs: { type: Array, required: true }
  },
  methods: {
    // @vuese
    // Zmiana zakładki
    // @arg indeks zakładki
    onChange(index) {
      // Informacja o zmienionej zakładce
      this.$emit('change', this.tabs[index]);
    },
    // @vuese
    // Zmiana tekstu wyszukiwania
    // @arg wyszukiwana fraza
    onSearch(search) {
      // Informaja o wprowadzeniu frazy do wyszukiwania
      this.$emit('search', search);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.tab-item {
  height: 48px;
  margin-right: 8px;
  background-color: rgba($color: #fff, $alpha: 0.5);
  text-transform: none !important;
  color: $color-active !important;

  i {
    color: $color-active !important;
  }

  &:first-of-type {
    margin-left: 24px;
  }
}

.v-tab--active {
  background-color: $color-active !important;
  color: $color-white !important;
  border-radius: 4px 4px 0 0;

  i {
    color: $color-white !important;
  }
}
</style>

<style lang="scss">
@import '@/styles/_variables.scss';
.tab-header {
  background-color: $color-active;
  width: 100%;
  padding: 24px 24px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  .v-input {
    input,
    label,
    i {
      color: $color-white !important;
    }
    .v-input__slot {
      background: rgba($color: #000000, $alpha: 0.2) !important;
    }
  }

  .v-input--is-focused {
    input,
    label,
    i {
      color: $color-active !important;
    }
    .v-input__slot {
      background: rgba($color: #fff, $alpha: 1) !important;
    }
  }
}

.tab-selector {
  display: flex;
  width: 100%;
}

.btn-add {
  background-color: $color-white !important;
  color: $color-active !important;
}

.list-counter-container {
  color: $color-active;
  background-color: $color-border;
  margin-top: -16px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4px 24px;
  font-size: $size-small;
  font-weight: $weight-semi-bold;
}

.v-input__slot {
  margin: 0 !important;
}

.v-slide-group__content {
  flex-wrap: wrap;
}

.v-tabs-bar {
  height: auto !important;
  background: none !important;
}

.v-tabs-slider-wrapper {
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .tab-item {
    &:first-of-type {
      margin-left: 0;
    }
  }

  .tab-header {
    padding: 16px 8px;
  }
}
</style>
