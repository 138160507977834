import { RouteConfig } from 'vue-router';

import RouterView from '@/components/RouterView.vue';
import AllPatients from '@/views/authenticated/patient/AllPatients.vue';
import Patients from '@/views/authenticated/patient/Patients.vue';
import HealthPlan from '@/views/authenticated/patient/HealthPlan.vue';

export const patientsRoute: RouteConfig = {
  path: 'patients',
  meta: { title: 'Ewidencja pacjentów' },
  component: RouterView,
  children: [
    {
      name: 'patients',
      path: '',
      component: Patients,
      meta: {
        title: 'Moi pacjenci',
        allPatients: false
      }
    },
    {
      name: 'all-patients',
      path: '/all-patients',
      component: AllPatients,
      meta: {
        title: 'Wszyscy pacjenci szpitala',
        allPatients: true
      }
    },
    {
      name: 'healthplan',
      path: 'healthplan/:id',
      component: HealthPlan,
      props: true,
      meta: {
        title: 'Plan zdrowia',
       
      }
    }
  ]
};
