import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Layout from '@/components/layout/Layout.vue';
import store from '@/store';
import { homeRoute } from './home.route';
import { patientsRoute } from './patients.route';
import { usersRoute } from './users.route';

Vue.use(VueRouter);

export const authenticatedRoute: RouteConfig = {
  path: '/',
  component: Layout,
  meta: {
    authorized: true,
    title: 'Ewidencja'
  },
  name: 'authenticated',
  redirect: { name: 'home' },
  children: [homeRoute, patientsRoute, usersRoute],
  beforeEnter: (to, from, next) => {
    store.dispatch('auth/updateTokenOnExpire');
    store.dispatch('auth/getProfile').then(() => next());
  }
};
