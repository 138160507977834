/* eslint-disable vue/valid-v-slot */
<template>
  <div>
    <h1 class="page-title">Użytkownicy</h1>

    <main-table :tabs="tabs" @change="tabChange" @search="onSearch">
      <template v-slot:actions>
        <v-dialog v-model="dialog" fullscreen>
          <template v-slot:activator="{ on, attrs }">
            <v-btn rounded large class="btn-add" elevation="0" @click="newItem" v-bind="attrs" v-on="on">
              <v-icon class="mr-3">mdi-plus</v-icon>Nowy Użytkownik
            </v-btn>
          </template>

          <user-form v-if="dialog" :id="form.id" @close="dialog = false" @delete="deleteItem" @saved="saved" />
        </v-dialog>
      </template>

      <v-data-table class="elevation-0" :headers="headers" :items="items" :loading="loading">
        <template v-slot:item.actions="{ item }" class="text-right">
          <div class="text-right">
            <v-btn fab elevation="0" color="primary" width="38" height="38" class="mr-2" @click="editItem(item)">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>

            <v-btn
              fab
              outlined
              elevation="0"
              color="grey"
              width="38"
              height="38"
              class="mr-2"
              @click="deleteItem(item)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </main-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import MainTable from '@/components/core/MainTable';
import UserForm from '@/components/forms/UserForm';
import { Role, RoleDisplayPlural } from '@/enums/role.enum';
import AccountService from '@/services/account.service';

/** @vuese
 * Lista użytkowników
 * @group user
 */
export default {
  components: { UserForm, MainTable },
  data() {
    return {
      dialog: false,
      loading: false,
      tabs: [
        { title: RoleDisplayPlural.User, filter: { role: Role.User } },
        { title: RoleDisplayPlural.Admin, filter: { role: Role.Admin } }
      ],
      headers: [
        { value: 'userName', text: 'Nazwa użytkownika' },
        { value: 'firstName', text: 'Imię' },
        { value: 'lastName', text: 'Nazwisko' },
        { value: 'email', text: 'E-mail' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      filters: { take: 0, role: 'User' },
      items: [],
      model: {
        userName: ''
      },
      form: {
        id: 0
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'Nowy użytkownik' : 'Edycja użytkownika';
    }
  },
  methods: {
    // Załadowanie listy
    load() {
      this.loading = true;
      AccountService.list(this.filters).then(response => {
        this.loading = false;
        this.items = response.data;
        this.total = response.total;
      });
    },
    // Zapis
    saved(response) {
      this.dialog = false;
      const updateIndex = this.items.findIndex(item => item.id == response.id);
      if (updateIndex === -1) {
        this.items.push(response);
        return;
      }
      Object.assign(this.items[updateIndex], response);
    },
    // Nowy użytkownik
    newItem() {
      this.editItem({ id: 0 });
    },
    // Edycja użytkownika
    editItem(item) {
      this.form.enable = true;
      this.dialog = true;
      this.form.id = item.id;
    },
    // Usunięcie użytkownika
    deleteItem(item) {
      if (confirm(`Na pewno chcesz usunąć użytkownika ${item.userName}`)) {
        AccountService.delete(item.id).then(response => {
          if (response.result) {
            const index = this.items.findIndex(listItem => listItem.id == item.id);
            this.$delete(this.items, index);
            this.dialog = false;
          }
        });
      }
    },
    // Obsługa zmiany zakładki
    tabChange(tab) {
      this.$set(this.filters, 'role', tab.filter.role);
      this.$nextTick(() => this.load());
    },
    // Obsługa wyszukiwania
    onSearch(search) {
      this.$set(this.filters, 'search', search);
      this.$nextTick(() => this.load());
    }
  },
  created() {
    this.load();
  }
};
</script>
