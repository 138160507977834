
<template>
  <v-col cols="12">
    <label>{{ title }}</label>
    <div v-if="textarea" class="chinfo textarea">{{ text }}</div>
    <div v-if="!textarea" class="chinfo">{{ text }}</div>
  </v-col>
</template>

<script>
export default {
  props: {
    model: {
      type: Object
    },

    textarea: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      return this.model.$model;
    },
    title() {
      return this.model.$title;
    }
  }
};
</script>
