import Vue from 'vue';
import VueDebounce from 'vue-debounce';
import VueI18n from 'vue-i18n';

import App from './App.vue';
import { CheckPermission } from './components/core/CheckPermission';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';

Vue.use(VueDebounce);
Vue.use(VueI18n);

Vue.component('check-permission', CheckPermission as any);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
