import { HealthPlan } from '../interfaces/healthPlan';
// import { ObjectList } from '../interfaces/objectList';
// import { Patient } from '../interfaces/patient';
import { BaseService } from './base.service';
import Client from './client';

export class HealthPlanService extends BaseService<HealthPlan> {
  protected resource: string;

  constructor() {
    super();
    this.resource = 'healthplan';
  }

  public get(id: number) {
    return Client.get(`/${this.resource}/${id}`).then(response => {
      return {
        ...JSON.parse(response.data.planJsonData),
        id: response.data.id,
        patientId: response.data.patientId,
        patientICD: response.data.patientICD,
        planStart: response.data.planStart,
        planEnd: response.data.planEnd
      };
    });
  }
  public create(payload: HealthPlan) {
    return Client.post(`/${this.resource}`, this.prepareSaveModel(payload)).then(response => response.data);
  }
  public update(id: number, payload: HealthPlan) {
    return Client.put(`/${this.resource}/${id}`, this.prepareSaveModel(payload)).then(response => response.data);
  }

  private prepareSaveModel(data: HealthPlan) {
    return {
      patientId: data.patientId,
      patientAddress: data.patientAddress,
      patientICD: data.patientICD,
      planStart: data.planStart,
      planEnd: data.planEnd,
      planJsonData: JSON.stringify(data)
    };
  }
}

export default new HealthPlanService();
