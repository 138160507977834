<template>
  <v-card>
    <v-form @submit.prevent="save">
      <div class="card__header page-content">
        <div class="header__text d-flex align-center">
          <v-btn rounded outlined large color="white" class="hide-sm" elevation="0" @click="close">
            <v-icon class="mr-3">mdi-arrow-left</v-icon>Wróć
          </v-btn>

          <h2 class="ml-4">{{ formTitle }}</h2>
        </div>

        <div v-if="!loading" class="header__actions">
          <check-permission permissions="Admin">
            <v-btn
              v-if="$schema.id.$model"
              rounded
              outlined
              large
              class="mr-3"
              color="white"
              elevation="0"
              @click="onDelete"
            >
              <v-icon class="mr-3">mdi-delete</v-icon>Usuń
            </v-btn>
          </check-permission>

          <v-btn v-if="editable" type="submit" color="white" rounded large elevation="0" :loading="saving">
            <v-icon class="mr-3">mdi-content-save</v-icon>Zapisz
          </v-btn>
        </div>
      </div>

      <div class="card__content">
        <div v-if="loading">
          Trwa ładowanie...
          <v-progress-linear indeterminate class="mb-0" />
        </div>

        <v-container v-else>
          <autocomplete
            v-if="profile.role == 'Admin'"
            counterLabel=""
            placeholder="Przypisz koordynatora"
            class="card-section"
            icon="mdi-doctor"
            :model="$schema.coordinatorsIds"
            :items="coordinators.list"
            :loading="coordinators.loading"
          >
            <template v-slot:header>
              <v-icon class="mr-3">mdi-doctor</v-icon>

              <h4>Koordynator</h4>

              <v-btn
                v-if="!isNewPatient"
                small
                outlined
                rounded
                color="primary"
                class="ml-4"
                @click.stop="history = true"
              >
                Zobacz historię zmian
              </v-btn>
            </template>
          </autocomplete>

          <div v-else class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-doctor</v-icon>

              <h4>Koordynatorzy</h4>
            </div>

            <v-row>
              <v-col v-if="$schema.coordinators.$model.length" cols="12" class="py-0">
                <v-chip
                  v-for="(coordinator, index) in $schema.coordinators.$model"
                  :key="'coordinator_' + index"
                  pill
                  class="patient__chip mr-4 mt-4"
                >
                  <v-avatar color="primary" left>
                    <v-icon color="white">mdi-account-circle</v-icon>
                  </v-avatar>

                  {{ coordinator.firstName }} {{ coordinator.lastName }}
                </v-chip>
              </v-col>

              <p v-else>- Brak przypisanego koordynatora -</p>
            </v-row>
          </div>

          <div class="card-section card-section--no-border">
            <div v-if="!isNewPatient" class="card-section__header">
              <v-icon class="mr-3">mdi-note-text</v-icon>

              <h4>Plany terapii</h4>

              <v-btn
                v-if="healthPlans.list.length"
                small
                outlined
                rounded
                color="primary"
                class="ml-4"
                @click.stop="previewHealthPlan(healthPlans.list[0].id)"
              >
                Zobacz aktualny plan
              </v-btn>

              <v-btn v-else small outlined rounded color="primary" class="ml-4" @click.stop="editHealthPlan(0)">
                Dodaj plan terapii zdrowienia
              </v-btn>
            </div>

            <template v-if="healthPlans.list.length">
              <v-row>
                <v-col cols="3" class="plans-cell"> Numer planu </v-col>

                <v-col cols="3" class="plans-cell"> Początek obowiązywania </v-col>

                <v-col cols="3" class="plans-cell"> Koniec obowiązywania </v-col>

                <v-col cols="3" class="plans-cell plans-cell--actions">
                  <v-btn small rounded elevation="0" color="primary" @click.stop="editHealthPlan(0)">
                    <v-icon class="mr-3">mdi-note-plus</v-icon>

                    Dodaj nowy plan
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-for="(plan, index) in healthPlans.list" :key="'plan_' + index" class="plans-item">
                <v-col cols="3" class="plans-cell">
                  {{ plan.id }}
                </v-col>

                <v-col cols="3" class="plans-cell">
                  {{ formatDate(plan.planStart) }}
                </v-col>

                <v-col cols="3" class="plans-cell">
                  {{ formatDate(plan.planEnd) }}
                </v-col>

                <v-col cols="3" class="plans-cell">
                  <v-btn small rounded block text color="primary" @click.stop="editHealthPlan(plan.id)">
                    Zobacz plan
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-card-account-details</v-icon>

              <h4>Dane pacjenta</h4>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <w-input :model="$schema.firstName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.lastName" :readonly="!editable" />
              </v-col>
              <v-col cols="12" md="6">
                <w-input :model="$schema.pesel" :readonly="!editable" />
              </v-col>

              <v-col cols="12" class="py-0">
                <w-input :model="$schema.diagnosis" :readonly="!editable" />
                <div class="v-messages">{{ icdText }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.address" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.contact" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.email" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Rodzaj opieki</h4>
            </div>

            <v-row>
              <v-col cols="6" class="py-0">
                <w-checkbox
                  v-for="type in types"
                  :key="'patient_type_' + type.value"
                  :value="type.value"
                  :model="$schema.type"
                  :label="type.label"
                  :readonly="!editable"
                />
              </v-col>
            </v-row>
          </div>

          <div v-if="isInType([1, 3])" class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Opiekun prawny</h4>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <w-input :model="$schema.details.legalGuardian.firstName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.legalGuardian.lastName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.legalGuardian.address" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.legalGuardian.contact" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div v-if="isInType([1])" class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Koordynator opieki</h4>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <w-input :model="$schema.details.careCoordinator.firstName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.careCoordinator.lastName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.careCoordinator.contact" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.careStart" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.careUnit" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.careBenefitDate" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div v-if="isInType([2])" class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Dane sądowe i teriapia</h4>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.courtTherapyStart" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.courtPZPVisit" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-textarea outlined :model="$schema.details.courtOrders" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-textarea outlined :model="$schema.details.courtCorrespondence" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div v-if="isInType([3])" class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Lekarz opieki czynnej</h4>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <w-input :model="$schema.details.communityDoctor.firstName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.communityDoctor.lastName" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.details.communityDoctor.contact" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.communityStart" :readonly="!editable" />
              </v-col>

              <v-col cols="12" md="6">
                <w-date-picker :model="$schema.details.communityVisit" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Historia leczenia</h4>
            </div>

            <v-row>
              <v-col cols="12" class="py-0">
                <w-textarea outlined :model="$schema.history" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Zgody</h4>
            </div>

            <v-row>
              <v-col cols="12" class="py-0">
                <w-textarea outlined :model="$schema.consents" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Zalecenia</h4>
            </div>

            <v-row>
              <v-col cols="12" class="py-0">
                <w-textarea outlined :model="$schema.recommendations" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Uwagi</h4>
            </div>

            <v-row>
              <v-col cols="12" class="py-0">
                <w-textarea outlined :model="$schema.details.notes" :readonly="!editable" />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </v-form>

    <v-dialog v-model="history" max-width="500">
      <v-card>
        <v-card-text>
          <patient-history v-if="history" :id="id" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="healthPlan" max-width="1200px">
      <v-card>
        <patient-health-plan
          v-if="healthPlan"
          :id="healthPlans.id"
          :patient="model"
          :start-date="(healthPlans.list.length && healthPlans.list[0].planEnd) || ''"
          @saved="healthPlanClose(true)"
          @close="healthPlanClose(false)"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { schemaMixin, WCheckbox, WDatePicker, WInput, WTextarea } from '@witkac/vuetify-components';
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import Autocomplete from '@/components/core/Autocomplete';
import PatientHistory from '@/components/core/PatientHistory';
import PatientHealthPlan from '@/components/core/PatientHealthPlan';
import { PatientType, PatientTypeDisplay } from '@/enums/patientType.enum';
import { Role } from '@/enums/role.enum';
import schema from '@/schemas/patient.schema.json';
import AccountService from '@/services/account.service';
import HealthPlanService from '@/services/healthPlan.service';
import PatientService from '@/services/patient.service';

import icddata from '@/data/icd10simple.json';
import router from '@/router';

export default {
  mixins: [schemaMixin],
  components: { Autocomplete, WCheckbox, WDatePicker, WInput, WTextarea, PatientHistory, PatientHealthPlan },
  schema,
  props: {
    id: { type: Number, required: true }
  },
  data: () => ({
    types: [
      { value: PatientType.ACTIVE_CARE, label: PatientTypeDisplay.ACTIVE_CARE },
      { value: PatientType.COURT_REGISTRY, label: PatientTypeDisplay.COURT_REGISTRY },
      { value: PatientType.COMMUNITY_TREATMENT, label: PatientTypeDisplay.COMMUNITY_TREATMENT }
    ],
    loading: false,
    saving: false,
    model: {
      userName: '',
      type: []
    },
    Role,
    coordinators: {
      list: [],
      loading: false
    },
    history: false,
    editable: false,

    healthPlan: false,
    healthPlans: {
      id: 0,
      list: [],
      loading: false
    }
  }),
  computed: {
    ...mapState({
      profile: state => state.auth.profile
    }),
    isNewPatient() {
      return !this.$schema.id.$model;
    },
    formTitle() {
      if (this.loading) {
        return '';
      }
      return this.isNewPatient ? 'Nowy pacjent' : `${this.$schema.firstName.$value} ${this.$schema.lastName.$value}`;
    },
    icdText() {
      var icd = this.model.diagnosis;
      if (icd) {
        var text = icddata[icd.toLocaleUpperCase()];
        if (text) {
          var p0 = icd.indexOf('.');
          if (p0 > -1) {
            var text0 = icddata[icd.substr(0, p0).toLocaleUpperCase()];
            if (text0) {
              text = text0 + '. ' + text;
            }
          }
          return text;
        }
      }
      return '';
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.saving = false;
      PatientService.get(this.id).then(account => {
        this.$schema.$setData(account);
        this.loadHealthPlans();
        this.loading = false;
        this.editable = this.profile.role === 'Admin' || account.coordinatorsIds.indexOf(this.profile.id) > -1;
      });
      if (this.profile.role === 'Admin') {
        this.loadCoordinators();
      }
    },
    close() {
      this.$emit('close');
    },
    save() {
      const errors = this.$schema.$validateAll();
      if (errors.length) {
        return;
      }
      this.saving = true;
      this.callSave().then(response => {
        this.saving = false;
        return this.$emit('saved', response);
      });
    },
    isInType(types) {
      for (var type of types) {
        if (this.$schema.type.$model.includes(type)) {
          return true;
        }
      }
      return false;
    },
    // Metoda zapisująca zmiany
    callSave() {
      const data = this.$schema.$getData();
      if (this.$schema.id.$model) {
        return PatientService.update(data.id, { ...data, id: undefined });
      }
      return PatientService.create(data);
    },
    loadCoordinators() {
      this.coordinators.loading = true;
      AccountService.list({ limit: -1 }).then(response => {
        this.coordinators.list = response.data.map(item => ({
          value: item.id,
          text: item.firstName + ' ' + item.lastName
        }));
        this.coordinators.loading = false;
      });
    },
    loadHealthPlans() {
      this.healthPlans.loading = true;
      HealthPlanService.list({ limit: -1, patientId: this.id }).then(response => {
        this.healthPlans.list = response.data;
        this.healthPlans.id = response.data[0] && response.data[0].id;
        this.healthPlans.loading = false;
      });
    },
    editHealthPlan(id) {
      this.healthPlans.id = id;
      this.healthPlan = true;
    },
    previewHealthPlan(id) {
      router.push({ path: '/patients/healthplan/' + id });
    },
    healthPlanClose(reloadList) {
      this.healthPlan = false;
      this.healthPlans.id = 0;
      if (reloadList) {
        this.loadHealthPlans();
      }
    },
    onDelete() {
      const data = this.$schema.$getData();

      this.$emit('delete', data);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    }
  },
  watch: {
    '$schema.coordinatorsIds.$model': function (newValue) {
      const coordinators = this.coordinators.list.filter(coordinator => newValue.some(id => id === coordinator.id));
      this.$schema.patients.$setData(coordinators);
    }
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.plans-item {
  border-top: solid 1px $color-border;
}

.plans-cell {
  border-right: solid 1px $color-border;

  &:last-of-type {
    border: none;
  }
}

.plans-cell--actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-section--no-border {
  border: none;
}
</style>
