/* eslint-disable vue/valid-v-slot */
<template>
  <div>
    <h1 class="page-title">{{ title }}</h1>

    <patients-table :tabs="tabs" :headers="headers" :preview="preview" />
  </div>
</template>

<script>
import PatientsTable from '@/components/PatientsTable';
import { PatientType, PatientTypeDisplay, PatientTypeIcon } from '@/enums/patientType.enum';

/** @vuese
 * Lista pacjentów
 * @group patient
 */
export default {
  components: { PatientsTable },
  data() {
    return {
      tabs: [
        { title: 'Wszyscy', icon: 'mdi-account-group' },
        {
          title: PatientTypeDisplay.ACTIVE_CARE,
          filter: { type: PatientType.ACTIVE_CARE },
          icon: PatientTypeIcon.ACTIVE_CARE
        },
        {
          title: PatientTypeDisplay.COMMUNITY_TREATMENT,
          filter: { type: PatientType.COMMUNITY_TREATMENT },
          icon: PatientTypeIcon.COMMUNITY_TREATMENT
        },
        {
          title: PatientTypeDisplay.COURT_REGISTRY,
          filter: { type: PatientType.COURT_REGISTRY },
          icon: PatientTypeIcon.COURT_REGISTRY
        }
      ],
      headers: [
        { value: 'pesel', text: 'PESEL' },
        { value: 'fullName', text: 'Imię i nazwisko' },
        { value: 'type', text: 'Rodzaj opieki' },
        { text: '', value: 'actions', sortable: false }
      ],
      preview: this.$route.query.preview
    };
  },
  computed: {
    title() {
      return this.$route?.meta?.title || 'Pacjenci';
    }
  },

  mounted() {
    // console.log('preview', this.preview);
  }
};
</script>
