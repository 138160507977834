<template>
  <v-app-bar app flat class="nav no-print" :clipped-left="$vuetify.breakpoint.lgAndUp">
    <div class="nav-container">
      <div class="nav-content">
        <div class="profile-type-container">
          <h2>CZP W SŁUPSKU</h2>

          <h2>{{ profile.firstName }} {{ profile.lastName }}</h2>
        </div>

        <template v-for="(item, index) in menu">
          <v-btn v-if="isAuthorized(item)" :key="index" elevation="0" class="nav-item" :to="item.to">
            <v-icon class="mr-3">{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-btn>
        </template>
      </div>

      <v-btn elevation="0" height="100%" class="logout-button" @click.prevent="logout">
        Wyloguj
        <v-icon class="ml-3">mdi-logout-variant</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

import { Role } from '@/enums/role.enum';

export default {
  data: () => ({
    title: process.env.PACKAGE_NAME,
    menu: [
      { icon: 'mdi-account-group', text: 'Moi pacjenci', roles: [Role.User], to: { name: 'patients' } },
      { icon: 'mdi-account-group-outline', text: 'Wszyscy pacjenci', to: { name: 'all-patients' } },
      { icon: 'mdi-doctor', text: 'Użytkownicy', roles: [Role.Admin], to: { name: 'users' } }
    ]
  }),
  computed: {
    ...mapState({
      profile: state => state.auth.profile
    })
  },
  methods: {
    logout() {
      this.$store.commit('auth/unauthorize');
      return this.$router.replace({ name: 'login' });
    },
    toggleDrawer() {
      this.$store.dispatch('navigation/toggleDrawer');
    },
    isAuthorized(item) {
      if (!item.roles) {
        return true;
      }
      return item.roles.includes(this.profile.role);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.nav {
  background-color: #fff !important;
  border-bottom: 1px solid $color-border !important;
}

.nav-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 80px 0 0;
}

.nav-content {
  display: flex;
}

.nav-item {
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: none !important;
  text-transform: none;
  height: 100% !important;
  border-radius: 0;
  margin: 0 16px;
  color: $color-text !important;
}

.logout-button {
  background: none !important;
  border-radius: 0;
}

.nav-item--active {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: $color-active;
  }
}

.profile-type-container {
  height: 100%;
  padding: 0 80px;
  background-color: $color-active;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;

  h2 {
    color: $color-white;

    &:first-of-type {
      font-weight: $weight-light;
    }
  }
}
</style>

<style>
.v-toolbar__content {
  padding: 0 !important;
}
</style>
