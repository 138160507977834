<template>
  <v-card>
    <v-form @submit.prevent="save" autocomplete="off">
      <div class="card__header page-content">
        <div class="header__text d-flex align-center">
          <v-btn rounded outlined large color="white" class="hide-sm" elevation="0" @click="close">
            <v-icon class="mr-3">mdi-arrow-left</v-icon> Wróć
          </v-btn>

          <h2 class="ml-4">
            {{ formTitle }}
          </h2>
        </div>

        <div v-if="!loading" class="header__actions">
          <v-btn rounded outlined large class="mr-3" color="white" elevation="0" @click="onDelete">
            <v-icon class="mr-3">mdi-delete</v-icon> Usuń
          </v-btn>

          <v-btn type="submit" color="white" rounded large elevation="0" :loading="saving">
            <v-icon class="mr-3">mdi-content-save</v-icon>Zapisz
          </v-btn>
        </div>
      </div>

      <div class="card__content">
        <div v-if="loading">
          Trwa ładowanie...
          <v-progress-linear indeterminate class="mb-0" />
        </div>

        <v-container v-else>
          <div class="card-section">
            <v-row>
              <v-col cols="12" md="6">
                <div class="card-section__header">
                  <v-icon class="mr-3">mdi-card-account-details</v-icon>

                  <h4>Rodzaj konta</h4>
                </div>

                <w-radio-group :model="$schema.role">
                  <v-radio v-for="role in roles" :key="role.value" :value="role.value" :label="role.label" />
                </w-radio-group>
              </v-col>

              <v-col cols="12" md="6">
                <autocomplete
                  counterLabel="Liczba przypisanych pacjentów:"
                  placeholder="Przypisz pacjentów do koordynatora"
                  :model="$schema.patientsIds"
                  :items="patients.list"
                  :loading="patients.loading"
                />
              </v-col>
            </v-row>
          </div>

          <div class="card-section">
            <div class="card-section__header">
              <v-icon class="mr-3">mdi-medical-bag</v-icon>

              <h4>Dane profilowe</h4>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <w-input :model="$schema.userName" autocomplete="off" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.email" />
              </v-col>

              <v-col cols="12" md="12"> </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.password" autocomplete="off" type="password" />

                <span v-if="this.id"
                  >Jeżeli chcesz zmienić hasło uzupełnij pola Hasło oraz Powtórz hasło.<br />W przeciwnym wypadku
                  pozostaw oba pola puste.
                </span>
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.confirmPassword" autocomplete="off" type="password" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.firstName" />
              </v-col>

              <v-col cols="12" md="6">
                <w-input :model="$schema.lastName" />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { schemaMixin, WInput, WRadioGroup } from '@witkac/vuetify-components';

import Autocomplete from '@/components/core/Autocomplete';
import { Role, RoleDisplay } from '@/enums/role.enum';
import PatientService from '@/services/patient.service';
import schema from '@/schemas/user.schema.json';
import AccountService from '@/services/account.service';

export default {
  mixins: [schemaMixin],
  components: { Autocomplete, WInput, WRadioGroup },
  props: {
    id: { type: Number, required: true }
  },
  data: () => ({
    model: {
      userName: '',
      patients: []
    },
    roles: [
      { value: Role.Admin, label: RoleDisplay.Admin },
      { value: Role.User, label: RoleDisplay.User }
    ],
    patients: {
      loaded: false,
      loading: false,
      list: []
    },
    loading: false,
    saving: false
  }),
  schema,
  computed: {
    newAccount() {
      return !this.$schema.id.$model;
    },
    formTitle() {
      if (this.loading) {
        return '';
      }
      return this.newAccount ? 'Nowy użytkownik' : `Edycja użytkownika #${this.$schema.id.$value}`;
    }
  },
  methods: {
    load() {
      if (!this.id) {
        this.$schema.$setData({ id: 0 });
      } else {
        this.loading = true;
        AccountService.get(this.id)
          .then(account => {
            this.$schema.$setData(account);
            this.loading = false;
          })
          .catch(err => alert(err.message || err));
      }
      this.loadPatients();
    },
    close() {
      this.$emit('close');
    },
    save() {
      const errors = this.$schema.$validateAll();
      if (errors.length) {
        return;
      }

      this.saving = true;
      this.callSave()
        .then(response => {
          this.saving = false;

          return this.$emit('saved', response);
        })
        .catch(err => {
          this.saving = false;
          let message = typeof err === 'string' ? err : err.message;
          if (err.errors) {
            message += err.errors.join(' ');
          }
          alert('Nie można wykonać akcji zapisu: ' + message);
        });
    },
    // Metoda zapisu
    callSave() {
      const data = this.$schema.$getData();
      if (this.$schema.id.$model) {
        return AccountService.update(data.id, { ...data, id: undefined });
      }
      return AccountService.create(data);
    },
    loadPatients() {
      this.patients.loading = true;
      PatientService.list({ limit: -1 }).then(response => {
        this.patients.list = response.data.map(item => ({
          value: item.id,
          text: item.firstName + ' ' + item.lastName
        }));
        this.patients.loaded = true;
        this.patients.loading = false;
      });
    },
    onDelete() {
      const data = this.$schema.$getData();
      this.$emit('delete', data);
    }
  },
  watch: {
    id: function(newValue) {
      this.load(newValue);
    },
    '$schema.patientsIds.$model': function(newValue) {
      const patients = this.patientsList.filter(patient => newValue.some(id => id === patient.id));
      this.$schema.patients.$setData(patients);
    }
  },
  created() {
    this.load(this.id);
  }
};
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
.patients-counter {
  display: flex;

  .patients-count {
    font-weight: $weight-bold;
  }
}
</style>
