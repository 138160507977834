import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pl from 'vuetify/src/locale/pl';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#168d79',
        secondary: '#4caf50',
        tertiary: '#495057',
        accent: '#168d79',
        error: '#EE5858',
        info: '#00d3ee',
        success: '#86E286',
        warning: '#ffa21a'
      }
    }
  },
  lang: {
    locales: { pl },
    current: 'pl'
  }
};
export default new Vuetify(opts);
