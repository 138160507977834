import { ObjectList } from '../interfaces/objectList';
import Client from './client';

export abstract class BaseService<T> {
  protected abstract resource: string;
  public list(query?: any): Promise<ObjectList<T>> {
    return Client.get(`/${this.resource}`, { params: query }).then(response => response.data);
  }
  public get(id: number) {
    return Client.get(`/${this.resource}/${id}`).then(response => response.data);
  }
  public create(payload: T) {
    return Client.post(`/${this.resource}`, payload).then(response => response.data);
  }
  public update(id: number, payload: T) {
    return Client.put(`/${this.resource}/${id}`, payload).then(response => response.data);
  }
  public delete(id: number) {
    return Client.delete(`/${this.resource}/${id}`).then(response => response.data);
  }
}
