import axios from 'axios';

import { SessionStorage } from '../enums/storage.enum';
import store from '@/store';

const baseDomain = process.env.VUE_APP_API_BASE;
const baseURL = `${baseDomain}/`;

const client = axios.create({ baseURL });

client.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem(SessionStorage.TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;

      if (!config.url?.startsWith('/accounts/')) {
        store.dispatch('auth/refreshTokenBeforeExpire');
      }
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error('err:', error.response);
    if (error.response?.status === 401 || error?.response?.data?.message == 'invalid token') {
      return store.dispatch('auth/unauthorize').then(() => window.location.replace('/login'));
    } else if (error.response?.data?.message) {
      return Promise.reject(error?.response?.data?.message);
    } else if (error?.response?.data?.title) {
      // TODO: Wyciągnąć dane z return error?.response?.data?.errors
      return Promise.reject(error?.response?.data?.title);
    }
    return Promise.reject(error);
  }
);
export default client;
