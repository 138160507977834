/* eslint-disable vue/valid-v-slot */
<template>
  <main-table :tabs="tabs" @change="tabChange" @search="onSearch">
    <template v-slot:actions>
      <v-dialog v-model="dialog" fullscreen>
        <template v-slot:activator="{ on, attrs }">
          <check-permission permissions="Admin">
            <v-btn rounded large class="btn-add" elevation="0" @click="newItem" v-bind="attrs" v-on="on">
              <v-icon class="mr-3">mdi-plus</v-icon>Nowy Pacjent
            </v-btn>
          </check-permission>
        </template>

        <patient-form v-if="dialog" :id="form.id" @delete="deleteItem" @close="dialog = false" @saved="saved" />
      </v-dialog>
    </template>

    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="items"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
    >
      <template v-slot:item.actions="{ item }" class="text-right">
        <div class="text-right">
          <check-permission>
            <v-btn fab elevation="0" color="primary" width="38" height="38" class="mr-2" @click="editItem(item)">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </check-permission>

          <check-permission permissions="Admin">
            <v-btn
              fab
              outlined
              elevation="0"
              color="grey"
              width="38"
              height="38"
              class="mr-2"
              @click="deleteItem(item)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </check-permission>
        </div>
      </template>

      <template v-slot:item.coordinators="{ item }">
        <span v-for="(coordinator, index) in item.coordinators" :key="'tab_coordinators_' + index">
          <br v-if="!!index" />{{ coordinator.firstName }} {{ coordinator.lastName }}
        </span>
      </template>

      <template v-slot:item.type="{ item }">
        <span v-for="(type, index) in item.type" :key="'tab_type_' + index">
          <br v-if="!!index" />
          {{ PatientTypeDisplay[PatientType[type]] }}
        </span>
      </template>

      <template v-slot:item.fullName="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
    </v-data-table>
  </main-table>
</template>

<script>
import MainTable from '@/components/core/MainTable';
import PatientForm from '@/components/forms/PatientForm';
import { PatientType, PatientTypeDisplay, PatientTypeIcon } from '@/enums/patientType.enum';
import PatientService from '@/services/patient.service';

/** @vuese
 * Lista pacjentów
 * @group patient
 */
export default {
  components: { PatientForm, MainTable },
  props: {
    tabs: { type: Array, required: true },
    headers: { type: Array, required: true },
    preview: { type: Number }
  },
  data() {
    return {
      PatientType,
      PatientTypeDisplay,
      PatientTypeIcon,
      dialog: false,
      loading: false,
      filters: { take: 0, all: false },
      items: [],
      total: 0,
      form: {
        id: 0
      }
    };
  },
  methods: {
    // Załadowanie listy
    load() {
      this.loading = true;
      return PatientService.list(this.filters).then(list => {
        this.loading = false;
        this.$set(this, 'items', list.data);
        this.$set(this, 'total', list.total);
      });
    },
    // Zapis zmian
    saved(response) {
      this.dialog = false;
      const updateIndex = this.items.findIndex(item => item.id == response.id);
      if (updateIndex === -1) {
        this.items.push(response);
        return;
      }
      Object.assign(this.items[updateIndex], response);
    },
    // Dodanie pacjenta
    newItem() {
      this.editItem({ id: 0 });
    },
    // Edycja pacjenta
    editItem(item) {
      this.dialog = true;
      this.form.id = item.id;
    },
    // Usunięcie pacjetna
    deleteItem(item) {
      if (confirm(`Na pewno chcesz usunąć pacjenta ${item.firstName} ${item.lastName}`)) {
        PatientService.delete(item.id).then(response => {
          if (response.result) {
            const index = this.items.findIndex(listItem => listItem.id == item.id);
            this.$delete(this.items, index);
            this.dialog = false;
          }
        });
      }
    },

    // Obsłużenie zmiany zakładki
    tabChange(tab) {
      this.$set(this.filters, 'type', tab.filter?.type);
      this.$nextTick(() => this.load());
    },
    // Obsłużenie wyszukiwania
    onSearch(search) {
      this.$set(this.filters, 'search', search);
      this.$nextTick(() => this.load());
    }
  },
  watch: {
    '$route.meta.allPatients': function (value) {
      this.filters.all = value;
      this.load();
    }
  },
  mounted() {
    this.filters.all = this.$route.meta?.allPatients || false;
    this.load();
    if (this.preview) {
      this.editItem({ id: this.preview });
    }
  }
};
</script>
