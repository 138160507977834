<template>
  <v-form>
    <div class="card__header--plan">
      <div class="header__text d-flex flex-column">
        <h2>Plan Terapii Zdrowienia</h2>

        <h2 v-if="patient">
          <span>{{ patient.firstName }} {{ patient.lastName }}</span>
          <span v-if="patient.address">, {{ patient.address }}</span>
          <span v-if="patient.pesel">, {{ patient.pesel }}</span>
          <span v-if="patient.diagnosis">, ICD-10: {{ patient.diagnosis }}</span>
        </h2>
      </div>

      <div class="header__actions">
        <v-btn color="white" rounded large elevation="0" class="mr-4" @click="myprint">
          <v-icon class="mr-3">mdi-printer</v-icon>Drukuj
        </v-btn>

        <v-btn color="primary" rounded large elevation="0" :loading="saving" @click="save">
          <v-icon class="mr-3">mdi-content-save</v-icon>Zapisz zaminy
        </v-btn>
      </div>
    </div>

    <div class="card__content">
      <div class="card-section">
        <v-row>
          <v-col cols="6">
            <w-date-picker :model="$schema.planStart" />
          </v-col>

          <v-col cols="6">
            <w-date-picker :model="$schema.planEnd" />
          </v-col>
        </v-row>
      </div>

      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>
          <h4>Obszar zdrowia</h4>
        </div>

        <v-row>
          <check-info :model="$schema.zdrowie" field="przyjmowanieLekow" />

          <check-info :model="$schema.zdrowie" field="wspolpracaWLeczeniu" />

          <check-info :model="$schema.zdrowie" field="inneChoroby" />

          <check-info :model="$schema.zdrowie" field="uzywanieSrodkowPsychoaktywnych" />

          <check-info :model="$schema.zdrowie" field="objawyChorobowe" />

          <check-info :model="$schema.zdrowie" field="tolerancjaLeczenia" />

          <v-col cols="12">
            <w-textarea outlined :model="$schema.zdrowie.ocenaSamopoczucia" />
          </v-col>

          <check-info :model="$schema.zdrowie" field="akceptacjaChoroby" />

          <v-col cols="12">
            <w-textarea outlined :model="$schema.zdrowie.potrzeby" />
          </v-col>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.zdrowie.inne" />
          </v-col>
        </v-row>
      </div>

      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Obszar edukacja i kompetencje zawodowe</h4>
        </div>

        <v-row>
          <v-col cols="12">
            <w-input :model="$schema.edukacja.status" />
          </v-col>
          <v-col cols="12">
            <w-input :model="$schema.edukacja.wyksztalcenie" />
          </v-col>
          <v-col cols="12">
            <w-input :model="$schema.edukacja.doswiadczenie" />
          </v-col>
          <check-info :model="$schema.edukacja" field="dalszaEdukacja" />
          <check-info :model="$schema.edukacja" field="zmianaKwalifikacji" />
          <v-col cols="12">
            <w-textarea outlined :model="$schema.edukacja.potrzeby" />
          </v-col>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.edukacja.inne" />
          </v-col>
        </v-row>
      </div>
      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Obszar status materialny i społeczny</h4>
        </div>

        <v-row>
          <check-info :model="$schema.materialny" field="korzystanieSpoleczna" />
          <check-info :model="$schema.materialny" field="statusPraca" />
          <check-info :model="$schema.materialny" field="dochod" />
          <check-info :model="$schema.materialny" field="obciazenia" />
          <v-col cols="12">
            <w-textarea outlined :model="$schema.materialny.warunkiMieszkaniowe" />
          </v-col>

          <check-info :model="$schema.materialny" field="orzeczenie" />
          <check-info :model="$schema.materialny" field="niezdolnosc" />
          <v-col cols="12">
            <w-textarea outlined :model="$schema.materialny.sytuacjaPrawna" />
          </v-col>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.materialny.potrzeby" />
          </v-col>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.materialny.inne" />
          </v-col>
        </v-row>
      </div>

      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Obszar kompetencji społecznych</h4>
        </div>

        <v-row>
          <check-info :model="$schema.spoleczne" field="higiena" />
          <check-info :model="$schema.spoleczne" field="dbalosc" />

          <v-col cols="12">
            <w-textarea outlined :model="$schema.spoleczne.funkcjonowanie" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.spoleczne.potrzeby" />
          </v-col>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.spoleczne.inne" />
          </v-col>
        </v-row>
      </div>

      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Obszar zainteresowania</h4>
        </div>

        <v-row>
          <check-info :model="$schema.zainteresowanie" field="czasWolny" />
          <v-col cols="12">
            <w-textarea outlined :model="$schema.zainteresowanie.umiejetnosci" />
          </v-col>

          <check-info :model="$schema.zainteresowanie" field="aktywnosc" />

          <v-col cols="12">
            <w-textarea outlined :model="$schema.zainteresowanie.potrzeby" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.zainteresowanie.inne" />
          </v-col>
        </v-row>
      </div>
      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Obszar rodzina i otoczenie</h4>
        </div>

        <v-row>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.rodzina.relacje" />
          </v-col>

          <v-col cols="12">
            <w-input :model="$schema.rodzina.opiekun" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.rodzina.sytuacja" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.rodzina.potrzeby" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.rodzina.inne" />
          </v-col>
        </v-row>
      </div>
      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Cele</h4>
        </div>

        <v-row v-for="(item, index) in $schema.cele.$items" :key="index">
          <v-col cols="12" class="d-flex">
            <w-input outlined :label="'Cel ' + (index + 1)" :model="item.$item.nazwa" />

            <v-btn text elevation="0" color="primary" @click="item.$item.$removeRow()">
              <v-icon class="mr-3">mdi-delete</v-icon>

              usuń
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mb-16">
          <v-col cols="12">
            <v-btn elevation="0" color="primary" @click="$schema.cele.$addRow()">Dodaj cel</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <w-textarea outlined :model="$schema.planPostepowania" />
          </v-col>

          <v-col cols="12">
            <w-textarea outlined :model="$schema.uwagi" />
          </v-col>
        </v-row>
      </div>
      <div class="card-section">
        <div class="card-section__header">
          <v-icon class="mr-3">mdi-card-account-details</v-icon>

          <h4>Realizacja celów</h4>
        </div>

        <v-row v-for="(item, index) in $schema.realizacjaCelow.$items" :key="'realizacja' + index">
          <v-col cols="12">
            <w-date-picker :model="item.$item.data" />
          </v-col>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="25%">Cel</th>
                    <th class="text-right">Ocena koordynatora</th>
                    <th class="text-right">Ocena pacjenta</th>
                    <th class="text-left" width="50%">Uwagi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(citem, cindex) in $schema.cele.$items" :key="'realizacja' + index + '_cel' + cindex">
                    <td>{{ cindex + 1 }}. {{ citem.$item.nazwa.$value }}</td>
                    <td>
                      <w-input outlined dense :model="citem.$item.oceny.$items[index].$item.ocenaKoordynatora" />
                    </td>
                    <td>
                      <w-input outlined dense :model="citem.$item.oceny.$items[index].$item.ocenaPacjenta" />
                    </td>
                    <td>
                      <w-input outlined dense :model="citem.$item.oceny.$items[index].$item.uwagiKoordynatora" />
                    </td>
                    <!--
                    <td><v-text-field v-model="citem.$item.ocenaPacjenta.$value"></v-text-field></td>
                    <td><v-text-field v-model="citem.$item.uwagiKoordynatora.$value"></v-text-field></td>
                    -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mb-16">
          <v-col cols="12">
            <v-btn elevation="0" color="primary" @click="$schema.realizacjaCelow.$addRow()"
              >Dodaj kolejną weryfikację</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </v-form>
</template>

<script>
import { schemaMixin, WInput, WTextarea, WDatePicker } from '@witkac/vuetify-components';
import schema from '@/schemas/healthplan.schema.json';
import CheckInfo from './CheckInfo.vue';
import HealthPlanService from '@/services/healthPlan.service';

export default {
  mixins: [schemaMixin],
  components: { WInput, WTextarea, WDatePicker, CheckInfo },
  schema,
  props: {
    patient: {
      type: Object
    },
    id: {
      type: Number
    },
    startDate: {
      type: String
    }
  },
  data() {
    return {
      model: {},
      loading: false,
      saving: false
    };
  },
  beforeMount() {
    this.copyPatient();
    this.load();
  },
  watch: {
    patient() {
      this.copyPatient();
    }
  },
  methods: {
    copyPatient() {
      if (this.patient) {
        this.model.patientId = this.patient.id;
        this.model.patientAddress = this.patient.address;
        this.model.patientICD = this.patient.diagnosis;
      }
    },
    load() {
      if (!this.id && this.startDate) {
        this.model.planStart = this.startDate;
      }
      if (this.id) {
        this.loading = true;
        this.saving = false;
        HealthPlanService.get(this.id).then(healthPlan => {
          this.$schema.$setData(healthPlan);
          this.loading = false;
        });
      }
    },
    myprint() {
      // eslint-disable-next-line no-debugger
      // debugger;
      this.$router.push({ path: '/patients/healthplan/' + this.id });
      // let routeData = this.$router.resolve({ path: '/patients/healthplan/' + this.id });
      // window.open(routeData.href, '_blank');
    },

    save() {
      const errors = this.$schema.$validateAll();
      if (errors.length) {
        return;
      }
      this.saving = true;
      this.callSave()
        .then(response => {
          this.saving = false;
          return this.$emit('saved', response);
        })
        .catch(err => alert(err.message || 'Nie można zapisać planu leczenia.'));
    },
    close() {
      this.$emit('close');
    },

    // Metoda zapisująca zmiany
    callSave() {
      const data = this.$schema.$getData();
      if (this.$schema.id.$model) {
        return HealthPlanService.update(data.id, data);
      }
      return HealthPlanService.create(data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.card__header--plan {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: auto;
  background-color: $color-border;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  z-index: +1;
  padding: 24px;
}

.card__content {
  margin-top: 0;
}

.v-input {
  margin-top: 12px;
}
</style>
