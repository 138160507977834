import { RouteConfig } from 'vue-router';

import { SessionStorage } from '../../enums/storage.enum';
import { loginRoute } from './login.route';

export const unauthenticatedRoute: RouteConfig = {
  path: '/',
  component: () => import(/* webpackChunkName: "StartLayout" */ '@/components/RouterView.vue'),
  children: [loginRoute],
  redirect: { name: 'login' },
  beforeEnter: (_to, _from, next) => {
    if (localStorage.getItem(SessionStorage.TOKEN)) {
      return next({ name: 'main' });
    }
    next();
  }
};
