export enum PatientType {
  ACTIVE_CARE = 1,
  COURT_REGISTRY = 2,
  COMMUNITY_TREATMENT = 3
}

export enum PatientTypeDisplay {
  ACTIVE_CARE = 'Opieka czynna',
  COURT_REGISTRY = 'Rejestr sądowy',
  COMMUNITY_TREATMENT = 'Zespół leczenia środowiskowego'
}

export enum PatientTypeIcon {
  ACTIVE_CARE = 'mdi-hospital-building',
  COURT_REGISTRY = 'mdi-gavel',
  COMMUNITY_TREATMENT = 'mdi-hospital-marker'
}
