import { mapState } from 'vuex';

export const CheckPermission: any = {
  props: {
    permissions: {
      type: [String, Array],
      required: false
    }
  },
  computed: {
    toCheck() {
      const toCheck: string[] = Array.isArray(this.permissions) ? this.permissions : [this.permissions];
      return toCheck;
    },
    ...mapState({
      hasPermission(state: any) {
        if (!this.permissions) {
          return true;
        }
        const profile = state.auth.profile;
        if (!profile.role) {
          return false;
        }
        return this.toCheck.includes(profile.role);
      }
    })
  } as any,
  beforeMount() {
    if (!this.permissions) {
      return;
    }
  },
  render() {
    if (this.hasPermission) {
      return this.$scopedSlots.default({});
    }
  }
};
