<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import './styles/main.scss';

export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  })
};
</script>
