// import { Dictionary } from 'vuex';

export abstract class AbstractModule {
  /**
   * Czy ma być używany namespace
   *
   * @type {boolean}
   * @memberof AbstractModule
   */
  public namespaced: boolean = true;

  /**
   * Stan store
   *
   * @abstract
   * @type {object}
   * @memberof AbstractModule
   */
  public abstract state: object;

  /**
   * Akcje
   *
   * @abstract
   * @memberof AbstractModule
   */
  public abstract actions: any; // Dictionary<(...args: any[]) => any>;

  /**
   * Mutacje
   *
   * @abstract
   * @memberof AbstractModule
   */
  public abstract mutations: any; // Dictionary<(...args: any[]) => void>;

  /**
   * Gettery
   *
   * @abstract
   * @memberof AbstractModule
   */
  public getters: any; // Dictionary<(localState: any) => any> = {};
}
