var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-table',{attrs:{"tabs":_vm.tabs},on:{"change":_vm.tabChange,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('check-permission',{attrs:{"permissions":"Admin"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"btn-add",attrs:{"rounded":"","large":"","elevation":"0"},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-plus")]),_vm._v("Nowy Pacjent ")],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('patient-form',{attrs:{"id":_vm.form.id},on:{"delete":_vm.deleteItem,"close":function($event){_vm.dialog = false},"saved":_vm.saved}}):_vm._e()],1)]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [50, 100, -1] }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('check-permission',[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","elevation":"0","color":"primary","width":"38","height":"38"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1),_c('check-permission',{attrs:{"permissions":"Admin"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","outlined":"","elevation":"0","color":"grey","width":"38","height":"38"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)]}},{key:"item.coordinators",fn:function(ref){
var item = ref.item;
return _vm._l((item.coordinators),function(coordinator,index){return _c('span',{key:'tab_coordinators_' + index},[(!!index)?_c('br'):_vm._e(),_vm._v(_vm._s(coordinator.firstName)+" "+_vm._s(coordinator.lastName)+" ")])})}},{key:"item.type",fn:function(ref){
var item = ref.item;
return _vm._l((item.type),function(type,index){return _c('span',{key:'tab_type_' + index},[(!!index)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.PatientTypeDisplay[_vm.PatientType[type]])+" ")])})}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }