/* eslint-disable no-unused-vars */
export enum SessionStorage {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
  // PROFILE_TYPE = 'profileType',
  // PROFILE_ID = 'profileId',
  // PARTNER_ID = 'partnerId ',
  // PLACE_ID = 'placeId',
  USER_ID = 'userId'
}
