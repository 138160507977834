import jwt from 'jsonwebtoken';

import { Profile } from '../../interfaces/profile';
import { Login } from '../../interfaces/login';
import { SessionStorage } from '../../enums/storage.enum';
import AccountService from '../../services/account.service';
import { AbstractModule } from './abstract.module';

class AuthModule extends AbstractModule {
  public state: any = { profile: undefined, permissions: {}, refreshId: undefined, expire: undefined };
  public mutations = {
    /**
     * Zapisuje dane profilu do store
     *
     * @param localState state
     * @param profile profil użytkownika
     */
    save(localState: any, profile: Profile): void {
      localState.profile = profile;
    },
    /**
     * Zapisuje uprawnienia do  store
     *
     * @param {*} localState
     * @param {object} permission
     */
    savePermissions(localState: any, permissions: any): void {
      localState.permissions = { ...localState.permissions, ...permissions };
    },

    /**
     * Ustawia id timeoutu odświeżającego token
     *
     * @param {*} localState
     * @param {boolean} refreshId
     */
    setRefreshId(localState: any, refreshId: boolean): void {
      localState.refreshId = refreshId;
    },

    /**
     * Ustawienie czasu wygaśnięcia tokena odczytując jwt
     *
     * @param {*} localState
     * @param {number} expire
     */
    setExpireFromToken(localState: any, token: string) {
      const tokenPayload: any = jwt.decode(token);
      if (tokenPayload.exp) {
        const expire = tokenPayload.exp * 1000;
        localState.expire = expire;
      }
    },

    /**
     * Ustawienie czasu wygaśnięcia tokena
     *
     * @param {*} localState
     * @param {number} expire
     */
    setExpire(localState: any, expire: number) {
      localState.expire = expire;
    },

    /**
     * Wylogowuje użytkownika
     *
     * @param {*} localState
     */
    unauthorize(localState: any) {
      if (localState.refreshId) {
        clearInterval(localState.refreshId);
      }
      localState.refreshId = null;
      localState.profile = null;
      localState.permissions = null;
      localState.expire = null;
      localStorage.setItem(SessionStorage.TOKEN, '');
      sessionStorage.setItem(SessionStorage.REFRESH_TOKEN, '');
      localStorage.clear();
      sessionStorage.clear();
    }
  };

  public actions = {
    /**
     * Logowanie
     *
     * @param context context
     * @param data dane logowania
     */
    login: async (context: any, data: Login): Promise<Profile> => {
      const { profile, token, refreshToken } = await AccountService.login(data.userName, data.password);

      localStorage.setItem(SessionStorage.TOKEN, token);
      sessionStorage.setItem(SessionStorage.REFRESH_TOKEN, refreshToken);
      localStorage.setItem(SessionStorage.USER_ID, '' + profile.id);
      context.commit('save', profile);
      context.commit('setExpireFromToken', token);
      context.dispatch('updateTokenOnExpire', token);
      return profile;
    },
    /**
     * Odświeża token
     *
     * @type {async}
     * @memberof AuthModule
     */
    refreshToken: async (context: any): Promise<void> => {
      const refreshTokenValue: string = '' + sessionStorage.getItem(SessionStorage.REFRESH_TOKEN);
      if (refreshTokenValue) {
        try {
          const { profile, token, refreshToken } = await AccountService.refreshToken(refreshTokenValue);

          localStorage.setItem(SessionStorage.TOKEN, token);
          sessionStorage.setItem(SessionStorage.REFRESH_TOKEN, refreshToken);
          localStorage.setItem(SessionStorage.USER_ID, '' + profile.id);
          context.commit('save', profile);
          context.commit('setExpireFromToken', token);
          context.dispatch('updateTokenOnExpire', token);
        } catch (err) {
          console.warn('unauthorized?', err);
          context.dispatch('unauthorize');
        }
      }
    },
    /**
     * Odświeża token, gdy zbliża się koniec ważności
     */
    refreshTokenBeforeExpire: async (context: any): Promise<void> => {
      const nowMS = new Date().getTime();
      const reservedTime = 1000 * 60 * 10;
      const expire = context.state.expire;
      if (expire && expire - nowMS - reservedTime <= 0) {
        context.commit('setExpire', expire + 5000); // ustawiamy expire na +5s by inne requesty nie próbowały
        await context.dispatch('refreshToken');
      }
    },
    /**
     * Okresowo odświeża token przed upływem ważności
     */
    updateTokenOnExpire: async (context: any, token: string): Promise<void> => {
      token = token || (localStorage.getItem(SessionStorage.TOKEN) as string);
      if (context.state.refreshId || !token) {
        return;
      }
      const tokenPayload: any = jwt.decode(token);
      if (tokenPayload.exp) {
        const tokenExpInMs = tokenPayload.exp * 1000;
        const reservedTime = 1000 * 60 * 5;
        const timeToExpiration = tokenExpInMs - new Date().getTime() - reservedTime;
        if (context.state.refreshId) {
          clearTimeout(context.state.refreshId);
        }
        const timeoutId = setTimeout(async () => {
          context.commit('setRefreshId', undefined);
          context.dispatch('refreshToken');
        }, timeToExpiration);
        context.commit('setRefreshId', timeoutId);
      }
    },
    getProfile: async (context: any): Promise<Profile> => {
      if (!context.state.profile) {
        await context.dispatch('refreshToken');
      }
      return context.state.profile;
    },
    unauthorize: (context: any): void => {
      context.commit('unauthorize');
    }
  };
}

export default new AuthModule();
