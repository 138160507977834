<template>
  <div>
    <div class="card-section__header">
      <slot name="header">
        <v-icon class="mr-3">{{ icon }}</v-icon>

        <h4>{{ label }}</h4>
      </slot>
    </div>

    <div v-if="counterLabel" class="patients-counter mb-2">
      <span>{{ counterLabel }}</span>

      <span class="patients-count ml-2">{{ svalue.length }}</span>
    </div>

    <v-autocomplete
      v-bind="$attrs"
      flat
      chips
      clearable
      outlined
      solo
      multiple
      item-value="value"
      :loading="loading"
      :value="svalue"
      :label="placeholder"
      :items="items"
      @focus="$emit('focus')"
      v-on="listeners"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          close
          pill
          class="patient__chip"
          :input-value="data.selected"
          @click:close="remove(data.item)"
        >
          <v-avatar color="primary" left>
            <v-icon color="white">mdi-account-circle</v-icon>
          </v-avatar>
          {{ data.item.text }}
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title> {{ data.item.text }} </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import baseComponent from '@witkac/vuetify-components/src/mixins/baseComponent';

export default {
  extends: baseComponent,
  name: 'autocomplete',
  props: {
    items: { type: Array, default: () => [] },
    itemValue: { type: String, default: 'id' },
    counterLabel: { type: String, default: 'Liczba:' },
    icon: { type: String, default: 'mdi-card-account-details' },
    loading: { type: Boolean, default: false },
    placeholder: { type: String }
  },
  computed: {
    svalue() {
      return this.model ? this.model.$model : [];
    }
  },
  methods: {
    // Ustawienie wartości
    $setValue(value) {
      if (this.model) {
        this.model.$model = value;
      }
    },
    // Usunięcie wiersza po kliknęciu w x
    remove(item) {
      const index = this.model.$model.findIndex(row => row == item.value);
      this.model.$removeRow(index);
    }
  }
};
</script>
