/* eslint-disable vue/valid-v-slot */
<template>
  <div>
    <h1 class="page-title">{{ title }}</h1>

    <patients-table :tabs="tabs" :headers="headers" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PatientsTable from '@/components/PatientsTable';
import { PatientType, PatientTypeDisplay, PatientTypeIcon } from '@/enums/patientType.enum';
import { Role } from '@/enums/role.enum';

/** @vuese
 * Lista pacjentów
 * @group patient
 */
export default {
  components: { PatientsTable },
  data() {
    return {
      tabs: [
        { title: 'Wszyscy', icon: 'mdi-account-group' },
        {
          title: PatientTypeDisplay.ACTIVE_CARE,
          filter: { type: PatientType.ACTIVE_CARE },
          icon: PatientTypeIcon.ACTIVE_CARE
        },
        {
          title: PatientTypeDisplay.COMMUNITY_TREATMENT,
          filter: { type: PatientType.COMMUNITY_TREATMENT },
          icon: PatientTypeIcon.COMMUNITY_TREATMENT
        },
        {
          title: PatientTypeDisplay.COURT_REGISTRY,
          filter: { type: PatientType.COURT_REGISTRY },
          icon: PatientTypeIcon.COURT_REGISTRY
        }
      ]
    };
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile
    }),
    title() {
      return this.$route?.meta?.title || 'Pacjenci';
    },
    headers() {
      const headers = [
        { value: 'pesel', text: 'PESEL' },
        { value: 'fullName', text: 'Imię i nazwisko' },
        { value: 'type', text: 'Rodzaj opieki' },
        { value: 'coordinators', text: 'Koordynator' }
      ];
      //if (this.profile.role === Role.Admin) {
        headers.push({ text: '', value: 'actions', sortable: false });
      //}
      return headers;
    }
  }
};
</script>
