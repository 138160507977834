<template>
    <v-col cols="12" class="check-info">

        <w-checkbox :model="model[field]" />

        <div class="check-info__content">
            <w-textarea v-if="textarea && model[field].$model" outlined label="" :model="model[field+'Info']"/>
            <w-input v-if="!textarea && model[field].$model" :label="''" :model="model[field+'Info']" />
        </div>
    </v-col>
</template>

<script>
import { WCheckbox,WTextarea,WInput } from '@witkac/vuetify-components';
export default {
    components: {WCheckbox,WTextarea,WInput},
    props: {
        model: {
            type: Object
        },
        field: {
            type: String
        },
        textarea: {
            type: Boolean,
            default: false
        }
    }
    
}
</script>

<style lang="scss" scoped>
    .check-info__content{
        margin-left:32px;
    }
</style>