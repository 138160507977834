<template>
  <v-col cols="12" class="check-info">
    <i v-if="checked" class="v-icon mdi mdi-check-box-outline"></i>
    <i v-else class="v-icon mdi mdi-checkbox-blank-outline"></i>
    <span>{{ title }}</span>

    <div v-if="textarea && checked" class="chinfo textarea">{{ text }}</div>
    <div v-if="!textarea && checked" class="chinfo">{{ text }}</div>
  </v-col>
</template>

<script>
export default {
  props: {
    model: {
      type: Object
    },
    field: {
      type: String
    },
    textarea: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checked() {
      return this.model[this.field].$model;
    },
    title() {
      return this.model[this.field].$title;
    },
    text() {
      return this.model[this.field + 'Info'].$model;
    }
  }
};
</script>

