<template>
  <div class="white">
    <div class="header__actions no-print" style="text-align: right">
      <br />
      <v-btn color="primary" rounded large elevation="0" class="mr-4">
        <v-icon class="mr-3" @click="myprint">mdi-printer</v-icon>Drukuj
      </v-btn>
    </div>

    <h1>
      Plan Terapii Zdrowienia <small>{{ $schema.planStart.$value }} - {{ $schema.planEnd.$value }}</small>
    </h1>

    <h2 v-if="patient">
      <span>{{ patient.firstName }} {{ patient.lastName }}</span>
      <span v-if="patient.address">, {{ patient.address }}</span>
      <span v-if="patient.pesel">, {{ patient.pesel }}</span>
      <span v-if="patient.diagnosis">, ICD-10: {{ patient.diagnosis }}</span>
    </h2>
    <br />
    <h3>Obszar zdrowia</h3>
    <check-info-preview :model="$schema.zdrowie" field="przyjmowanieLekow"></check-info-preview>
    <check-info-preview :model="$schema.zdrowie" field="wspolpracaWLeczeniu"></check-info-preview>
    <check-info-preview :model="$schema.zdrowie" field="inneChoroby"></check-info-preview>
    <check-info-preview :model="$schema.zdrowie" field="uzywanieSrodkowPsychoaktywnych"></check-info-preview>
    <check-info-preview :model="$schema.zdrowie" field="objawyChorobowe"></check-info-preview>
    <check-info-preview :model="$schema.zdrowie" field="tolerancjaLeczenia"></check-info-preview>
    <info-preview :model="$schema.zdrowie.ocenaSamopoczucia" textarea />
    <check-info-preview :model="$schema.zdrowie" field="akceptacjaChoroby"></check-info-preview>
    <info-preview :model="$schema.zdrowie.potrzeby" textarea />
    <info-preview :model="$schema.zdrowie.inne" textarea />

    <br />
    <h3>Obszar edukacja i kompetencje zawodowe</h3>
    <info-preview :model="$schema.edukacja.status" />
    <info-preview :model="$schema.edukacja.wyksztalcenie" />
    <info-preview :model="$schema.edukacja.doswiadczenie" />
    <check-info-preview :model="$schema.edukacja" field="dalszaEdukacja"></check-info-preview>
    <check-info-preview :model="$schema.edukacja" field="zmianaKwalifikacji"></check-info-preview>
    <info-preview :model="$schema.edukacja.potrzeby" textarea />
    <info-preview :model="$schema.edukacja.inne" textarea />
    <br />
    <h4>Obszar status materialny i społeczny</h4>
    <check-info-preview :model="$schema.materialny" field="korzystanieSpoleczna"></check-info-preview>
    <check-info-preview :model="$schema.materialny" field="statusPraca"></check-info-preview>
    <check-info-preview :model="$schema.materialny" field="dochod"></check-info-preview>
    <check-info-preview :model="$schema.materialny" field="obciazenia"></check-info-preview>
    <info-preview :model="$schema.materialny.warunkiMieszkaniowe" textarea />
    <check-info-preview :model="$schema.materialny" field="orzeczenie"></check-info-preview>
    <check-info-preview :model="$schema.materialny" field="niezdolnosc"></check-info-preview>
    <info-preview :model="$schema.materialny.sytuacjaPrawna" textarea />
    <info-preview :model="$schema.materialny.potrzeby" textarea />
    <info-preview :model="$schema.materialny.inne" textarea />
    <br />
    <h3>Obszar kompetencji społecznych</h3>
    <check-info-preview :model="$schema.spoleczne" field="higiena"></check-info-preview>
    <check-info-preview :model="$schema.spoleczne" field="dbalosc"></check-info-preview>
    <info-preview :model="$schema.spoleczne.funkcjonowanie" textarea />
    <info-preview :model="$schema.spoleczne.potrzeby" textarea />
    <info-preview :model="$schema.spoleczne.inne" textarea />

    <br />
    <h3>Obszar zainteresowania</h3>
    <check-info-preview :model="$schema.zainteresowanie" field="czasWolny"></check-info-preview>
    <info-preview :model="$schema.zainteresowanie.umiejetnosci" textarea />
    <check-info-preview :model="$schema.zainteresowanie" field="aktywnosc"></check-info-preview>
    <info-preview :model="$schema.zainteresowanie.potrzeby" textarea />
    <info-preview :model="$schema.zainteresowanie.inne" textarea />
    <br />
    <h3>Obszar rodzina i otoczenie</h3>
    <info-preview :model="$schema.rodzina.relacje" textarea />
    <info-preview :model="$schema.rodzina.opiekun" />
    <info-preview :model="$schema.rodzina.sytuacja" textarea />
    <info-preview :model="$schema.rodzina.potrzeby" textarea />
    <info-preview :model="$schema.rodzina.inne" textarea />
    <br />
    <h3>Cele</h3>
    <div v-for="(item, index) in $schema.cele.$items" :key="index">
      {{ index + 1 + '. ' + item.$item.nazwa.$model }}
    </div>
    <br />
    <template v-if="model.realizacjaCelow && model.realizacjaCelow.length">
      <h3>Realizacja celów</h3>
      <table class="simpleborder">
        <thead>
          <tr>
            <th class="text-left" rowspan="2">Cel</th>
            <th v-for="(real, rindex) in model.realizacjaCelow" :key="'rel2' + rindex" colspan="2">
              {{ real.data }}
            </th>
          </tr>
          <tr>
            <template v-for="(real, rindex) in model.realizacjaCelow">
              <th :key="'rel3' + rindex">Koord</th>
              <th :key="'rel4' + rindex">Pacjent</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cel, index) in model.cele" :key="'rel' + index">
            <td>{{ index + 1 }}. {{ cel.nazwa }}</td>
            <template v-for="(real, rindex) in cel.oceny">
              <td class="text-center" :key="'rel5' + index + 'r' + rindex">{{ real.ocenaKoordynatora }}</td>
              <td class="text-center" :key="'rel6' + index + 'r' + rindex">{{ real.ocenaPacjenta }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { schemaMixin } from '@witkac/vuetify-components';
import schema from '@/schemas/healthplan.schema.json';
import HealthPlanService from '@/services/healthPlan.service';
import PatientService from '@/services/patient.service';
import CheckInfoPreview from '@/components/core/CheckInfoPreview.vue';
import InfoPreview from '@/components/core/InfoPreview.vue';

export default {
  components: { CheckInfoPreview, InfoPreview },
  mixins: [schemaMixin],
  schema,
  props: {
    id: {
      type: [Number, String]
    },
    autoprint: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      model: {},
      loading: false,
      patient: null
    };
  },
  beforeMount() {
    this.load();
  },

  methods: {
    myprint() {
      window.print();
    },
    load() {
      if (this.id) {
        this.loading = true;
        HealthPlanService.get(this.id).then(healthPlan => {
          this.$schema.$setData(healthPlan);
          console.log('healthPlan', healthPlan);
          this.loading = false;
          if (!this.patient && healthPlan.patientId) {
            PatientService.get(healthPlan.patientId).then(account => {
              this.patient = account;
              if (this.autoprint) {
                this.$nextTick(() => {
                  window.print();
                });
              }
              // this.$schema.$setData(account);
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
table.simpleborder {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}
table.simpleborder th,
table.simpleborder td {
  border: 1px solid black;
  padding: 0 2px 0 2px;
}
</style>