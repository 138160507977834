<template>
  <v-row>
    <v-col cols="12" class="mb-8">
      <h4>Historia zmian</h4>
    </v-col>

    <v-col cols="12">
      <div class="history__timeline">
        <div v-for="(historyEntry, index) in list" :key="index" class="history__item" :class="entryClass(historyEntry)">
          <div class="history-item__date">{{ formatDate(historyEntry.date) }}</div>
          <v-chip pill class="patient__chip">
            <v-avatar v-if="actionIcon(historyEntry)" color="primary" left>
              <v-icon color="white">{{ actionIcon(historyEntry) }}</v-icon>
            </v-avatar>

            <template v-if="historyEntry.action == 1">Utworzenie pacjenta</template>

            <template v-else>
              {{ historyEntry.comment }}
            </template>
          </v-chip>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PatientService from '@/services/patient.service';
const allowedActionTypes = [1, 4, 5];
export default {
  props: {
    id: { type: Number, required: true }
  },
  data() {
    return { list: [] };
  },
  methods: {
    actionIcon(historyEntry) {
      switch (historyEntry.action) {
        case 4:
          return 'mdi-plus-circle';
        case 5:
          return 'mdi-minus-circle';
      }
      return '';
    },
    entryClass(historyEntry) {
      const cssClass = {
        'item--marked': historyEntry.action == 4,
        'item--unmarked': historyEntry.action == 5,
        'item--start': historyEntry.action == 1
      };

      return cssClass;
    },
    formatDate(dateToFormat) {
      const date = new Date(dateToFormat);
      return `${date.getDay()}.${date.getMonth() + 1}.${date.getFullYear()} `;
    },
    mapHistory(list) {
      return list
        .filter(item => allowedActionTypes.includes(item.action))
        .map(item => {
          return { ...item, icon: this.actionIcon(item) };
        });
    }
  },
  mounted() {
    PatientService.history(this.id).then(result => {
      this.list = this.mapHistory(result.data);
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.history__timeline {
  display: flex;
  flex-direction: column;
  border-left: solid 2px $color-border;
}

.history__item {
  margin-left: 24px;
  position: relative;
  margin-bottom: 24px;

  display: flex;
  align-items: center;

  &:first-of-type {
    margin-top: -16px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -31px;

    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: $color-active;
  }
}

.item--unmarked {
  .v-chip {
    background: none !important;
  }
  .v-icon {
    color: $color-border !important;
  }
  .v-avatar {
    background-color: $color-icon !important;
  }
}

.item--start {
  margin-bottom: -16px;
}

.history-item__date {
  margin-right: 24px;
  font-size: $size-small;
  color: $color-icon;
}

.item--marked {
}
</style>
